module.exports = {
  production: {
    API_HOST: 'https://pm.vfine.ru:4000',
    MEDIA_HOST: 'https://pm.vfine.ru:4000',
    MEDIA_URL: 'https://pm.vfine.ru:4000/media/',
    WS_URL: 'wss://pm.vfine.ru:4000/',
  },
  development: {
    ISDEV: true,
    API_HOST: 'http://pm.vfine.ru:4001',
    WS_URL: 'ws://pm.vfine.ru:4001/',
  }
}