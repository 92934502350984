<template>
  <div id="Pic">
    <BarCom>
      <template v-slot:header>
        <div class="item icon pointer" @click="update();"><i class="fas fa-sync-alt"></i></div>
      </template>
    </BarCom>
    <div class="container">
      <div v-for="img in images" :key="img.id" class="image" :style="img.style"></div>
    </div>
  </div>
</template>
<script>
import BarCom from '@/com/main/BarCom.vue'

export default {
  components: {
    BarCom
  },

  created: function () {
    document.title = 'Pic'
    this.update()
  },

  data() { return {
    images: []
  }},

  methods: {
    update: function() {
      this.$api({
        path: 'pic',
        method: 'get',
        loading: true,
      }).then(response => {
        this.images = response.data.data
      })
    }
	}

}
</script>
<style scoped lang="scss">@import './pic.scss';</style>
<style lang="scss">
#Pic #headerCom {
  .switch-shade {
    display: none;
  }
  .item.text {
    font-weight:700;
  }
}
</style>