<template>
  <div id="Cappers">
    
    <BarCom>
      <template v-slot:header>
        <div class="item icon pointer" @click="$s();$modal('capper', null)"><i class="fas fa-plus"></i></div>
        <Fltr v-if="!$root.isMobile" :resetfilter="resetFilter"></Fltr>
      </template>
      <template v-slot:shade>
        <h2>Фильтр:</h2>
        <Fltr :resetfilter="resetFilter"></Fltr>
      </template>
    </BarCom>
    
    <div class="cappers loading" v-if="cappers">
      <div class="capper" v-for="item in cappers.items" :key="item.capper.id">
        <div class="line">
          <div class="bets">
            <div v-for="bet in item.bets" :key="bet.id" :class="'bet status-'+bet.status" @click="$s();$modal('bet', {capper: item.capper, bet: bet})" :title="bet.bet">
              <div class="date">{{ bet.datef }}</div>
              <div class="coeff">{{ bet.coeff }}</div>
              <div class="sport">{{ bet.sport[0] }}</div>
              <div class="summ" v-if="item.capper.is_my">{{ bet.summ }}</div>
              <div class="reverse_coeff" v-else>{{ bet.reversecoeff }}</div>
              <!-- <div class="p">bet.p1</div> -->
            </div>
          </div>
          <div class="name"><a @click="$s();$modal('capper', item.capper.id)">{{ item.capper.name }}</a> <i class="fas fa-chart-bar" @click="$s();$modal('plot', Object.assign({id: item.capper.id}, $root.storage.cappers.filter))"></i></div>
          <div class="bet-add" @click="$s();$modal('bet', {capper: item.capper, bet: {}})"><i class="fas fa-plus"></i></div>
        </div>
        <div class="capper-info" v-html="item.info"></div>
      </div>
    </div>

    <Modal id='capper'>
      <Capper :cappersGet="cappersGet"></Capper>
    </Modal>

    <Modal id='plot' mode='fullscreen'>
      <Plot></Plot>
    </Modal>

    <Modal id='bet'>
      <Bet :cappersGet="cappersGet"></Bet>
    </Modal>

  </div>
</template>

<script>
import BarCom from '@/com/main/BarCom.vue'
import Modal from '@/com/main/Modal.vue'
import Fltr from './Fltr.vue'
import Capper from './Capper.vue'
import Bet from './Bet.vue'
import Plot from './Plot.vue'

export default {
  components: {
    BarCom,
    Modal,
    Fltr,
    Capper,
    Bet,
    Plot
  },

  created: function () {
    let t = this
    document.title = 'Статистика'

    this.cappersGet(true)

    this.ws = new this.$Websocket(this, 'cappers')
    this.ws.onmessage = function(e) {
      if (e.data==='update') {
        t.cappersGet()
      }
    }
    this.ws.open()

    document.addEventListener('visibilitychange', this.visibilitychange)
  },

  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.visibilitychange)
  },

  data() { return {
    ws: null,
    cappers: null
	}},

  methods: {
    
    visibilitychange: function() {
      if (!document.hidden) {
        this.cappersGet()
      }
    },

    cappersGet: function(loading) {
      this.$api({method: 'get', path: 'cappers/cappers', loading:loading, params: this.$root.storage.cappers.filter}).then(response => { this.cappers = response.data.data })
    },

    resetFilter: function(exclude) {
      if (!exclude) exclude=[]
      for (var n in this.$root.storage.cappers.filter) {
        if (exclude.indexOf(n) == -1) this.$root.storage.cappers.filter[n] = null
      }
      this.cappersGet(true)
      this.$root.barComShadeShow = false
    },
    
  }

}
</script>

<style scoped lang="scss">@import './cappers.scss';</style>
<style lang="scss">
.desctop #Cappers #headerCom .switch-shade {
  display: none;
}
</style>