<template>
  <div id="Auth">
    <form @submit="$clickSound();auth()" onsubmit="return false">
      <div class="label">Логин</div>
      <input type="text" class="form-control" v-model="username">
      <div class="label">Пароль</div>
      <input type="password" class="form-control" v-model="password">
      <button type="submit">Войти</button>
    </form>
  </div>
</template>

<script>
export default {
  created() {
    document.title = 'Авторизация'
  },
  
  data: function() { return {
		username: '',
    password: ''
	}},

  methods: {
    auth: function() {
      this.$api({
        path: 'authorization',
        method: 'post',
        loading: true,
        auth: false,
        data: {
          username: this.username,
          password: this.password
        }
      }).then(response => {
        if (!response.data.error) {
          this.$root.storage.currentComponent = 'Home'
        } else {
          this.$root.alert = 'Неверные данные'
        }
      })
    }
	}
}
</script>
<style scoped lang="scss">
  #Auth {
    position: relative;
  }
  form {
    position: fixed;
    width: 240px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid $bg-020;
      background-color: transparent;
      font-size: 1.325rem;
      color:$color-007;
      padding-bottom:2px;
      transition: 0.2s all;
    }
    input:focus {
      border-bottom-color:$bg-010;
    }
    .label {
      margin:20px 0 5px 0;
      font-size: 0.875rem;
      color:$color-015;
    }
    .label:first-of-type {
      margin-top:0;
    }
    button {
      margin-top:30px;
      width: 100%;
      font-size: 1.125rem;
      padding: 7px;
      border: none;
      background-color:$bg-101;
      color: $color-049;
      border-radius: 4px;
      cursor: pointer;
    }
  }
</style>