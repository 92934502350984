<template>
  <div id="Bet">
    <div class="header">Редактировать ставку</div>
    <div class="body">
      <form id="BetForm" @submit="$s();submit()" onsubmit="return false">
        <div class="item" id="id_date">
          <div class="label">* Дата:</div>
          <div class="field"><date-picker :input-attr=datePickerAttr v-model="article.date" value-type='format' format="YYYY-MM-DD"></date-picker>
          </div>
        </div>
        <div class="item"  id="id_timef" v-if="article.timef">
          <div class="label">Время:</div>
          <div class="field">{{ article.timef }}</div>
        </div>
        <div class="item" id="id_order" v-if="article.id">
          <div class="label">Сортировка:</div>
          <div class="field"><input type="number" v-model="article.order" /></div>
        </div>
        <div class="item" id="id_coeff">
          <div class="label">* Кэф:</div>
          <div class="field"><input type="number" step="0.001" min="0" v-model="article.coeff" /></div>
        </div>
        <div class="item" id="id_status">
          <div class="label">Статус:</div>
          <div class="field">
            <select v-model="article.status">
              <option value="0">Нет</option>
              <option value="1">Прошел</option>
              <option value="2">Возврат</option>
              <option value="3">Не прошел</option>
            </select>
          </div>
        </div>
        <div class="item" id="id_sport">
          <div class="label">Спорт:</div>
          <div class="field">
            <select v-model="article.sport">
              <option value="Теннис">Теннис</option>
              <option value="Футбол">Футбол</option>
              <option value="Хоккей">Хоккей</option>
              <option value="Баскетбол">Баскетбол</option>
              <option value="Волейбол">Волейбол</option>
              <option value="Киберспорт">Киберспорт</option>
              <option value="Единоборства">Единоборства</option>
              <option value="Футзал">Футзал</option>
            </select>
          </div>
        </div>
        <div class="item" id="id_summ" v-if="$root.modalData.capper.is_my">
          <div class="label">Сумма:</div>
          <div class="field"><input type="number" min="0" v-model="article.summ" /></div>
        </div>
        <div class="item" id="id_bet">
          <div class="label">Ставка:</div>
          <div class="field"><input type="text" v-model="article.bet" /></div>
        </div>
      </form>
    </div>
    <div class="footer">
      <button v-if="article.id" class="danger left" @click="$s();$confirm('Удалить?', del)">Удалить</button>
      <button class="success" form="BetForm">Сохранить</button>
      <button @click="$s();$modal('hide')">Отмена</button>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import '@/assets/datepicker.scss'
import 'vue2-datepicker/locale/ru'

export default {
  components: {
    DatePicker
  },

  props: {   
    cappersGet: Function
  },

  data() { return {
    article: {
      id: this.$root.modalData.bet.id,
      capper: this.$root.modalData.capper.id,
      date: this.$root.storage.cappers.bet.date,
      coeff: this.$root.storage.cappers.bet.coeff,
      status: this.$root.storage.cappers.bet.status,
      sport: this.$root.storage.cappers.bet.sport,
      summ: this.$root.storage.cappers.bet.summ,
      bet: null
    },
    datePickerAttr: {readonly: true}
  }},

  created: function() {
    if (this.article.id) this.get()
  },

  methods: {
    submit: function() {
      if (this.article.id) { this.put() } else { this.post() }
    },
    
    get: function() {
      this.$api({method: 'get', path: 'cappers/bet', loadingModal:true, params: {id: this.article.id}}).then(response => { this.article = response.data.data })
    },

    post: function() {
      this.$api({method: 'post', path: 'cappers/bet', loadingModal:true, data: this.article}).then(request => {
        if(this.$modalCheckFormErrors(request)) {
          this.$root.storage.cappers.bet = this.article
          this.$modal('hide')
          this.cappersGet(false)
        }
      })
    },

    put: function() {
      this.$api({method: 'put', path: 'cappers/bet', loadingModal:true, data: this.article}).then(request => {
        if(this.$modalCheckFormErrors(request)) {
          this.$modal('hide')
          this.cappersGet(false)
        }
      })
    },

    del: function() {
      this.$api({method: 'delete', path: 'cappers/bet', loadingModal:true, params: {id: this.article.id }}).then(() => {
        this.$modal('hide')
        this.cappersGet(false)
      })
    }
  }
}
</script>
<style lang="scss">

</style>