<template>
  <div id="Plot">
    <div class="header">График</div>
    <div class="body">
      <div class="img" :class="{ maxsize: maxsize }">
        <img v-if="data.img" :src="$config.MEDIA_URL + data.img" alt="">
      </div>
    </div>
    <div class="footer">
      <div class="size">
        <input type="checkbox" v-model="maxsize" @click="$s();" /> Оригинальный размер
      </div>
      <button @click="$s();$modal('hide')">Отмена</button>
    </div>
  </div>
</template>

<script>
export default {
  data() { return {
    data: {
      img: null
    },
    maxsize: false
	}},

  created: function() {
    this.get()
  },

  methods: {
    get: function() {
      this.$api({method: 'get', path: 'cappers/plot', loadingModal:true, params: this.$root.modalData}).then(response => { this.data = response.data.data })
    }
  }
}
</script>
<style scoped lang="scss">
  #Plot {
    .body {
      text-align: center;
      img {
        max-width: 100%;
      }
      .img.maxsize {
        overflow-x: scroll;
        img {
          max-width: none;
        }
      }
    }
    .footer {
      .size {
        display:inline-block;
        position:relative;
        top:5px;
        padding-left:4px;
        input {
          transform:scale(1.3);
          margin-right: 7px;
          position:relative;
          top:2px;
        }
      }
    }
  }
</style>