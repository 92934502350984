<template>
  <div class="filter">
    <div class="item text">месяц:</div>
    <div class="item input"><date-picker :input-attr=datePickerAttr v-model="$root.storage.cappers.filter.month" type="month" value-type='format' format="YYYY-MM" v-on:change="resetfilter(['month'])"></date-picker></div>
    <div class="item text">день:</div>
    <div class="item input"><date-picker :input-attr=datePickerAttr v-model="$root.storage.cappers.filter.day" value-type='format' format="YYYY-MM-DD" v-on:change="resetfilter(['day'])"></date-picker></div>
    <div class="item text">от:</div>
    <div class="item input"><date-picker :input-attr=datePickerAttr v-model="$root.storage.cappers.filter.from" value-type='format' format="YYYY-MM-DD" v-on:change="resetfilter(['from', 'to'])"></date-picker></div>
    <div class="item text">до:</div>
    <div class="item input"><date-picker :input-attr=datePickerAttr v-model="$root.storage.cappers.filter.to" value-type='format' format="YYYY-MM-DD" v-on:change="resetfilter(['from', 'to'])"></date-picker></div>
    <div class="item icon pointer" @click="$s();resetfilter()"><i class="fas fa-times"></i></div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import '@/assets/datepicker.scss'
import 'vue2-datepicker/locale/ru'

export default {
  components: {
    DatePicker
  },

  props: {
    resetfilter: Function
  },

  data() { return {
		datePickerAttr: {readonly: true}
	}}

}
</script>
<style lang="scss">
  #Cappers {
    .filter {
      .input .mx-icon-calendar,
      .input .mx-icon-clear {
        display: none;
      }
    }
    #headerCom .filter {
      display:inline-block;
      white-space: nowrap;
      height: 100%;
      .input input {
        width: 90px;
      }
    }
  } 
</style>