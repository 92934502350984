<template>
  <div id="Bar">
    <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <div id="shade" v-if="$root.barShadeShow">
        <div class="first">
          <a @click="$clickSound();$com('Home')"><i class="fas fa-home"></i>Дом</a>
          <a @click="$clickSound();$com('News')"><i class="fas fa-newspaper"></i>Новости</a>
          <a @click="$clickSound();$com('Pic')"><i class="fas fa-image"></i>Pic</a>
          <a @click="$clickSound();$com('Live')"><i class="fas fa-futbol"></i>Live</a>
          <a @click="$clickSound();$com('Cappers')"><i class="fas fa-chart-area"></i>Статистика</a>
          <a @click="$clickSound();$com('Odds')"><i class="fas fa-money-check-alt"></i>Прогрузы</a>
          <a @click="$clickSound();$com('Tuning')"><i class="fas fa-cog"></i>Настройки</a>
          <a @click="$clickSound();$logout()"><i class="fas fa-door-open"></i>Выход</a>
        </div>
        <div class="second">

        </div>
        <div class="version">Версия: {{ $version.num }} {{ $version.prefix }}</div>
        <div class="on close" @click="$clickSound();$barShadeToggle()"><i class="far fa-times-circle"></i></div>
      </div>
    </transition>
    <div id="header">
      <div class="item icon switch-shade" @click="$clickSound();$barShadeToggle()"><i class="fas fa-ellipsis-h"></i></div>
      <div class="item icon check" v-bind:class="{ ok: $root.check, transfer: $root.transfer }"><i class="fas fa-network-wired"></i></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  #shade {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2500;
    overflow: hidden;
    background-color: $bg-051;
    color:$color-049;
    animation-duration: 0.2s;
    padding:40px 40px 40px 40px;

    .close {
      padding: 20px;
      cursor: pointer;
      position: absolute;
      top:0;
      right:0;
      font-size: 2rem;
    
      &.close:hover {
        color: $color-114;
        transition: all 0.2s;
      }
    }
    a {
      color:$color-049;
    
      &:hover {
        color: $color-114;
        transition: all 0.2s;
      }
    }
    .first {
      float:left;
      border-right:1px solid $bg-020;
      height:100%;
      padding:0 40px 0 0;
      a {
        font-size: 1.25rem;
        display: block;
        margin-bottom:25px;
        cursor: pointer;

        i {
          width: 30px;
          font-size: 1rem;
          position: relative;
        }
      }
    }
    .second {
      float:left;
      padding:0 0 0 40px;
    }
    .version {
      font-size: 0.75rem;
      color: $color-020;
      clear:both;
      margin-top:30px;
    }
  }

  #header {
    background-color: $bg-055;
    height: $bar-height;
    overflow: hidden;
    position:fixed;
    width:100%;
    top:0;
    color:$color-030;
    z-index:2000;

    .item {
      position: relative;
      height:100%;
      display:inline-block;
      white-space: nowrap;
      cursor:default;
    }
    .icon {
      width:40px;
    }
    .icon i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .icon.pointer:hover {
      color: $color-037;
    }
    .text {
      white-space: nowrap;
      padding: 0 10px;
      line-height: $bar-height;
    }
    .item.switch-shade {
      cursor: pointer;
      color: $bg-101;
      font-size: 1.825rem;
    }
    .item.switch-shade:hover {
      color: $bg-105;
    }
    .item.check {
      font-size: 0.875rem;
      color:$color-015;
    }
    .item.check.ok {
      color:$color-049;
    }
    .item.check.transfer {
      color:$bg-501;
    }
  }

  .mobile {
    #header {
      height: $bar-height-mobile;
      .text {
        line-height: $bar-height-mobile;
      }
    }
  }

  @media (max-width: 500px) {
    #shade {
      .first {
        float:none;
        border-right:none;
        border-bottom:1px solid $bg-020;
        height:auto;
        padding:0 0 10px 0;
        margin-bottom: 40px;
      }
      .second {
        float:none;
        padding:0;
      }
    }
  }
</style>