<template>
  <div id="main" v-bind:class="{ mobile: $root.isMobile, desctop: $root.isDesctop, 'modal-show': $root.modal }">
    <Bar v-if="$root.auth"></Bar>
    <component v-bind:is="$root.storage.currentComponent" v-bind:class="{ loading: $root.loading }" class="component"></component>

    <loading v-if="$root.loading"></loading>

    <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <div id="alert" v-if="$root.alert" @click="$clickSound();$alert('hide')">
        <div>{{ $root.alert }}</div>
      </div>
    </transition>

    <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <div id="confirm" v-if="$root.confirm" @click="$clickSound();$confirm('hide')">
        <div class="content">
          <div class="msg">{{ $root.confirm }}</div>
          <button @click="$root.confirmFunc(); $confirm('hide')">Подтвердить</button>
          <button @click="$confirm('hide')">Отмена</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import '@/assets/fontawesome/css/all.min.css'
import '@/assets/animate.min.css'
import './main.scss'
import Loading from './Loading.vue'
import Bar from './Bar.vue'
import Auth from './Auth.vue'
import Home from './Home.vue'
import Live from '@/com/live/Live.vue'
import Cappers from '@/com/cappers/Cappers.vue'
import Tuning from '@/com/tuning/Tuning.vue'
import Odds from '@/com/odds/Odds.vue'
import Pic from '@/com/pic/Pic.vue'
import News from '@/com/news/News.vue'

export default {
  components: {
    Loading,
    Auth,
    Bar,
    Home,
    Live,
    Cappers,
    Tuning,
    Odds,
    Pic,
    News
  }
}
</script>

<style scoped lang="scss">

</style>