import axios from 'axios'
import axiosRetry from 'axios-retry'

axiosRetry(axios)

function install(Vue) {

  Vue.prototype.$clickSound = function() {
    this.$root.mobileFunction.clickSound()
  }
  
  Vue.prototype.$get_cookie = function(cookie_name) {
    let results = document.cookie.match ('(^|;) ?' + cookie_name + '=([^;]*)(;|$)')
    if ( results )
      return ( unescape ( results[2] ) )
    else
      return null
  }

  Vue.prototype.$barShadeToggle = function() {
    this.$root.barShadeShow = !this.$root.barShadeShow
  }

  Vue.prototype.$barComShadeToggle = function() {
    this.$root.barComShadeShow = !this.$root.barComShadeShow
  }
  
  Vue.prototype.$logout = function() {
    this.$api({path: 'logout', loading: true})
  }

  Vue.prototype.$com = function(component) {
    this.$root.storage.currentComponent = component
    this.$root.barShadeShow = false
  }

  Vue.prototype.$api = function(options) {
    let _options = {
      method: 'get',
      path: 'check',
      loading: false,
      loadingModal: false,
      data: {},
      params: {},
      retries: 0,
      retrydelay: 3000,
      auth: true,
      host: this.$config.API_HOST,
      error: 'Ошибка соединения.',
      errorRetries: 'Попытка подключения ...',
      catch: false
    }
    if (options.loading) _options.retries = 20
    let t = this
    let opt = _options
    if (options) opt = Object.assign(_options, options)
    if (opt.loading) t.$root.loading = opt.loading
    if (opt.loadingModal) t.$root.loadingModal = opt.loadingModal

    function complete() {
      if (opt.loading) {
        t.$root.loading = false
        t.$root.loadingLog = ''
      }
      if (opt.loadingModal) {
        t.$root.loadingModal = false
        t.$root.loadingModalLog = ''
      }
    }

    t.$root.transfer = true
    const result = axios({
      method: opt.method,
      url: opt.host + '/' + opt.path + '/',
      data: opt.data,
      params: opt.params,
      withCredentials: true,
      headers: {'Cookie-Frontend': document.cookie},
      'axios-retry': {
        retries: opt.retries,
        retryDelay: () => opt.retrydelay,
        retryCondition: function() {
          t.$root.check = false
          
          if (opt.retries) {
            if (opt.loading) t.$root.loadingLog = opt.error + ' ' + opt.errorRetries
            if (opt.loadingModal) t.$root.loadingModalLog = opt.error + ' ' + opt.errorRetries
          }

          return axiosRetry.isNetworkOrIdempotentRequestError
        }
      }
    }).then(response => {
      
      if (response.data.result === undefined) throw new Error('ErrorRequestApi')

      if (response.headers['set-cookie-frontend']) {
        document.cookie = response.headers['set-cookie-frontend']
      }

      t.$root.check = true
      t.$root.transfer = false
      t.$root.auth = response.data.auth

      complete()

      t.$root.updateVersion(response)

      if (opt.auth && !response.data.auth) {
        t.$com('Auth')
        throw new Error('NotAuthorized')
      }
      return response
    })

    return result.catch((e) => {
      t.$root.transfer = false
      if (e.message == 'NotAuthorized') {
        if (opt.catch) { throw new Error('NotAuthorized') } else { console.log('Error: NotAuthorized') }
      } else {
        if (opt.loading || opt.loadingModal) {
          if (!t.$root.storage.currentComponent) {
            t.$root.created()
          } else {
            complete()
            t.$alert(opt.error)
            if (opt.loadingModal) t.$root.modal('hide')
          }
        }
        if (opt.catch) { throw new Error('ErrorRequestApi') } else { console.log('Error: ErrorRequestApi') }
      }
    })
  }

  Vue.prototype.$modalCheckFormErrors = function(request) {
    let errorEls = document.querySelectorAll('.modal .body .item .field .errors')
    errorEls.forEach(el => {
      el.remove()
    })
    let fieldEls = document.querySelectorAll('.modal .body .item .field.error')
    fieldEls.forEach(el => {
      el.classList.remove('error')
    })
    
    if (request.data.error) {
      let data = request.data.data;
      for (var field in data) {
        let errorList = data[field]
        let fieldEl = document.querySelector('#id_'+field+' .field')
        let errorEl = document.createElement('div')
        fieldEl.classList.add('error')
        errorEl.className = 'errors'
        let errorString = ''
        errorList.forEach(e => {
          errorString += e + ' '
        })
        errorEl.innerHTML = errorString
        fieldEl.appendChild(errorEl)
      }
      return false
    }
    return true
  }

  Vue.prototype.$modal = function(opt, data) {
    if (opt == 'hide') {
      this.$root.modal = false
      this.$root.modalData = null
    } else {
      this.$root.modal = opt
      this.$root.modalData = data
    }
  }

  Vue.prototype.$alert = function(opt) {
    if (opt == 'hide') {
      this.$root.alert = false
    } else {
      this.$root.alert = opt
    }
  }

  Vue.prototype.$confirm = function(opt, f) {
    if (opt == 'hide') {
      this.$root.confirm = false
      this.$root.confirmFunc = null
    } else {
      this.$root.confirm = opt
      this.$root.confirmFunc = f
    }
  }

  Vue.prototype.$datetime = function(format) {
    if (!format) format = 'Y-m-d'
    var now = new Date();
    const replaces = {
      Y: now.getFullYear(),
      m: ('0'+(now.getMonth()+1)).slice(-2),
      d: ('0'+now.getDate()).slice(-2),
      H: ('0'+now.getHours()).slice(-2),
      i: ('0'+now.getMinutes()).slice(-2),
      s: ('0'+now.getSeconds()).slice(-2)
    }
    let result = format
    for(const replace in replaces) {
      result = result.replace(replace,replaces[replace]);
    }
    return result
  }

  Vue.prototype.$Websocket = function(com, group, every) {
    this.com = com
    this.group = group
    this.every = every //  не ограничивается компонентом
  }
  Vue.prototype.$Websocket.prototype = {
    onopen: function() {
      this.com.$root.check = true
      console.log('websocket opened: ' + this.group)
    },
    onmessage: function() {
    },
    onclose: function() {
      console.log('websocket closed: ' + this.group)
    },
    onerror: function(error) {
      console.log(error.message)
    },
    open: function() {
      let t = this
      let com = this.com
      let ws =  new WebSocket(this.com.$config.WS_URL + 'ws/' + this.group + '?' + document.cookie)
      ws.onopen = this.onopen.bind(this)
      ws.onmessage = this.onmessage
      ws.onclose = this.onclose.bind(this)
      ws.onerror = this.onerror

      let timer = setInterval(function() {
        if (!t.every && com.$root.storage.currentComponent.toString().toLowerCase() != t.group.toString()) {
          ws.close()
          clearInterval(timer)
          return null
        }
        if (ws.readyState === WebSocket.CLOSED) {
          com.$root.check = false
          com.ws.open()
          clearInterval(timer)
        }
      }, 3000)

      return ws
    },
    close: function() {
      if (this.ws != null) {
        this.ws.close()
        this.ws = null
      }
    }
  }
}

export default install;