<template>
  <div id="News">
    <BarCom>
      <template v-slot:header>
        <div class="item icon pointer" @click="update();"><i class="fas fa-sync-alt"></i></div>
      </template>
    </BarCom>
    <div class="container">
      <div v-for="item in news" :key="item.id" class="item">.{{ item.message }}</div>
    </div>
  </div>
</template>
<script>
import BarCom from '@/com/main/BarCom.vue'

export default {
  components: {
    BarCom
  },

  created: function () {
    let t = this
    document.title = 'Новости'
    
    this.update()

    this.ws = new this.$Websocket(this, 'news')
    this.ws.onmessage = function(e) {
      t.news.push(
        {'id': e.data, 'message': e.data}
      )
      t.$root.mobileFunction.tts(e.data)
    }
    this.ws.open()
  },

  data() { return {
    news: []
  }},

  methods: {
    update: function() {
      this.$root.mobileFunction.playAudio('https://pm.vfine.ru:4000/media/tts.mp3')
      this.$root.mobileFunction.vibrate(100)
      this.$root.mobileFunction.toastLong('Hello Kitty')
      this.$api({
        path: 'news',
        method: 'get',
        loading: true,
      }).then(response => {
        this.news = response.data.data
      })
    }
	}

}
</script>
<style scoped lang="scss">@import './news.scss';</style>
<style lang="scss">
#News #headerCom {
  .switch-shade {
    display: none;
  }
  .item.text {
    font-weight:700;
  }
}
</style>