<template>
  <div id="Capper">
    <div class="header">{{ title }}</div>
    <div class="body">
      <form id="CapperForm" @submit="$s();submit()" onsubmit="return false">
        <div class="item" id="id_name">
          <div class="label">Название:</div>
          <div class="field"><input type="text" v-model="article.name" />
          </div>
        </div>
        <div class="item" id="id_order">
          <div class="label">Сортировка:</div>
          <div class="field"><input type="number" v-model="article.order" /></div>
        </div>
        <div class="item" id="id_is_my">
          <div class="label">Мои ставки:</div>
          <div class="field"><input type="checkbox" v-model="article.is_my" /></div>
        </div>
      </form>
    </div>
    <div class="footer">
      <button v-if="article.id" class="danger left" @click="$s();$confirm('Удалить?', del)">Удалить</button>
      <button class="success" form="CapperForm">Сохранить</button>
      <button @click="$s();$modal('hide')">Отмена</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {   
    cappersGet: Function
  },

  data() { return {
    article: {
      id: this.$root.modalData,
      order: 0
    }
	}},

  created: function() {
    if (this.article.id) this.get()
  },

  computed: {
    title: function() {
      if (this.id) { return 'Редактировать каппера' } else {
        return 'Создать каппера'
      }
    }
  },

  methods: {
    submit: function() {
      if (this.article.id) { this.put() } else { this.post() }
    },
    
    get: function() {
      this.$api({method: 'get', path: 'cappers/capper', loadingModal:true, params: {id: this.article.id}}).then(response => { this.article = response.data.data })
    },

    post: function() {
      this.$api({method: 'post', path: 'cappers/capper', loadingModal:true, data: this.article}).then(request => {
        if(this.$modalCheckFormErrors(request)) {
          this.$modal('hide')
          this.cappersGet(false)
        }
      })
    },

    put: function() {
      this.$api({method: 'put', path: 'cappers/capper', loadingModal:true, data: this.article}).then(request => {
        if(this.$modalCheckFormErrors(request)) {
          this.$modal('hide')
          this.cappersGet(false)
        }
      })
    },

    del: function() {
      this.$api({method: 'delete', path: 'cappers/capper', loadingModal:true, params: {id: this.article.id }}).then(() => {
        this.$modal('hide')
        this.cappersGet(false)
      })
    }
  }
}
</script>
<style lang="scss">

</style>